<template>
  <div class="config_tips" v-if="isShowConfigTips">
    <img src="@/assets/home/icon.png" alt="icon" class="svg" />
    <div class="right_box">
      <div class="top">
        <div class="title">配置提醒</div>
        <p class="close" @click="isShowConfigTips = false">x</p>
      </div>
      <div class="content">
        您的电脑配置低于比赛设备推荐要求，可继续进行赛前测试，但正式比赛过程中可能会出现不可控的异常情况!<br />
        <div class="check">
          (PS：系统检测到您的电脑{{ isTotal ? "可分配内存不足" : ""
          }}{{ isTotal && (isDownlink || isFreeSpace) ? "、" : ""
          }}{{ isDownlink ? "当前网络不佳" : ""
          }}{{ isDownlink && isFreeSpace ? "、" : ""
          }}{{ isFreeSpace ? "硬盘可用空间不足" : "" }}
          )
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();

onMounted(() => {
  getSysInfo();
});

const isShowConfigTips = ref(false),
  isTotal = ref(false),
  isDownlink = ref(false),
  isFreeSpace = ref(false),
  //系统信息获取
  getSysInfo = () => {
    const os = require("os");

    const freeSpaceInGB = os.freemem() / (1024 * 1024 * 1024),
      memoryUsage = performance.memory.usedJSHeapSize,
      total = performance.memory.jsHeapSizeLimit / (1024 * 1024),
      downlink = navigator.connection.downlink;

    isTotal.value = total < 250;
    isDownlink.value = downlink < 10;
    isFreeSpace.value = freeSpaceInGB < 60;

    isShowConfigTips.value = [
      isTotal.value,
      isDownlink.value,
      isFreeSpace.value,
    ].includes(true);
  };
</script>

<style lang="less" scoped>
.config_tips {
  width: 384px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #dcdcdc;
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;

  .svg {
    width: 24px;
    height: 24px;
  }

  .right_box {
    flex: 1;
    margin-left: 8px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        height: 24px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;
      }

      .close {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .content {
      width: 320px;
      height: fit-content;
      font-size: 14px;
      font-weight: 400;
      color: #e37318;
      line-height: 22px;
      margin-top: 8px;

      .check {
        display: flex;
        color: #cc0000;
        font-weight: bold;
      }
    }
  }
}
</style>
