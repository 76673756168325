<!-- 创客 -->
<template>
  <!-- 学生信息 -->
  <p class="first-row">{{ score.name }}&nbsp;同学</p>

  <p class="second-row">
    {{ score.study }}{{ score.grade }} {{ score.school }}
  </p>

  <!-- 广州、江门 -->
  <template v-if="score.showStatus == 1">
    <div class="third-row">
      <template v-if="[1, 2].includes(score.awardLevel)">
        <div class="first_col">
          恭喜你&nbsp;
          <p class="red_text">已晋级</p>
          &nbsp;省级决赛，并获得&nbsp;
          <p class="red_text">{{ riseMap[score.awardLevel] }}</p>
        </div>
        <p>很棒，继续加油！期待决赛中你的表现！</p>
      </template>
      <template v-else-if="[3, 4].includes(score.awardLevel)">
        <div class="first_col">
          恭喜你获得&nbsp;
          <p class="red_text">{{ riseMap[score.awardLevel] }}</p>
          &nbsp;，但距离晋级省赛还差一点！
        </div>
        <p>你已经很棒了，希望继续加油，期待你在下次比赛中的优异表现！</p>
      </template>
      <template v-else>
        <div class="top">未获奖</div>
        <div class="bottom">
          有点小遗憾，本次比赛未能获奖。但你已经很棒了，<br />
          未来的道路仍然充满无限可能，请继续努力，相信你今后一定会取得优异的成绩！
        </div>
      </template>
    </div>

    <div class="fourth-row" v-if="score.awardLevel">
      <img
        :src="
          require(`@/assets/home/qck/reward${
            score.awardLevel <= 2 ? 0 : score.awardLevel
          }.png`)
        "
        alt="img"
        class="reward_img"
      />
    </div>

    <div class="fifth-row" v-if="score.awardLevel">
      获奖证书资讯，请关注“<a :href="linkUrl" target="_blank" class="link"
        >广东省青少年社会教育协会</a
      >”公众号，点击查看<a :href="linkUrl" target="_blank" class="link"
        >相关通知</a
      >。
    </div>
  </template>

  <!-- 深圳 -->
  <template v-else-if="score.showStatus == 2">
    <div class="third-row">
      <template v-if="score.showRise > 1">
        <div class="first_col">
          恭喜你&nbsp;
          <p class="red_text">已晋级</p>
          &nbsp;省级决赛
        </div>
        <p>很棒，继续加油！期待决赛中你的表现！</p>
      </template>
      <template v-else>
        <div class="top">未晋级</div>
        <div class="bottom">
          有点小遗憾，本次比赛未能获奖。但你已经很棒了，<br />
          未来的道路仍然充满无限可能，请继续努力，相信你今后一定会取得优异的成绩！
        </div>
      </template>
    </div>

    <div class="fourth-row" v-if="score.showRise > 1">
      <img src="@/assets/home/qck/reward0.png" alt="img" class="reward_img" />
    </div>
  </template>

  <!-- 省赛 -->
  <template v-else-if="score.showStatus == 3">
    <div class="third-row">
      <template v-if="score.awardLevel">
        <div class="first_col">
          恭喜你获得&nbsp;
          <p class="red_text">{{ riseMap[score.awardLevel] }}</p>
        </div>
        <p>{{ riseTipsMap[score.awardLevel] }}</p>
      </template>
      <template v-else>
        <div class="top">未获奖</div>
        <div class="bottom">
          有点小遗憾，本次比赛未能获奖。但你已经很棒了，<br />
          未来的道路仍然充满无限可能，请继续努力，相信你今后一定会取得优异的成绩！
        </div>
      </template>
    </div>

    <div class="fourth-row" v-if="score.awardLevel">
      <img
        :src="require(`@/assets/home/qck/reward${score.awardLevel}.png`)"
        alt="img"
        class="reward_img"
      />
    </div>
  </template>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, inject } from "vue";

const { proxy } = getCurrentInstance();

defineProps({ score: Object });

const riseMap = { 1: "一等奖", 2: "二等奖", 3: "三等奖", 4: "优胜奖" },
  riseTipsMap = {
    1: "这是一个不错的起点，请继续努力，我相信你还有更多的收获。",
    2: "这是一个美好的开始，保持坚持和努力，相信你会获得更多的成就。",
    3: "这体现了你的努力和付出。期待你在未来的挑战中创造更辉煌的成绩。",
    4: "这证明你已做出不菲的努力，请继续攀登，相信你的坚持会有更好的收获。",
  };
</script>

<style lang="less" scoped>
.first-row,
.second-row {
  font-size: 28px;
  font-weight: 600;
  color: #0052d9;
  line-height: 56px;

  &.first-row {
    padding-top: 72px;

    &.first-row-pass {
      padding-top: 48px;
    }
  }
}

.third-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  color: #00a870;
  margin-top: 27px;

  .first_col {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  .red_text {
    color: #e34d59;
    font-size: 36px;
  }

  .top {
    height: fit-content;
    font-size: 48px;
    font-weight: 500;
    color: rgba(61, 61, 61, 0.9);
    line-height: 44px;
    padding-top: 24px;
  }

  .bottom {
    font-size: 18px;
    font-weight: 600;
    color: #596077;
    line-height: 26px;
    text-align: center;
    margin-top: 48px;
  }
}

.fourth-row {
  height: fit-content;
  margin-top: 24px;

  .reward_img {
    width: 480px;
    height: 360px;
  }
}

.fifth-row {
  font-size: 16px;
  margin-top: 24px;

  .link {
    color: #0441d4;
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
