<template>
  <p class="title">{{ config.competition[VUE_APP_GAME] }}</p>
  <p class="title">{{ comMess.eventName }}</p>
  <p class="sub-title">
    <template v-if="VUE_APP_GAME == 'qck'">
      {{ comMess.groupName }} {{ comMess.langName }}
      {{ comMess.raceZonePast ?? comMess.raceZone }}
      {{
        comMess.racePeriod
          ? periodMap[comMess.racePeriod]
          : areaMap[comMess.matchArea]
      }}
    </template>
    <template v-else-if="VUE_APP_GAME == 'hdycp'"
      >{{ comMess.groupName }} {{ comMess.langName }}</template
    >
    <template v-else>{{ comMess.langName }}</template>
  </p>
</template>

<script setup>
import { config } from "@/config";
const { VUE_APP_GAME } = process.env,
  props = defineProps({ comMess: Object }), // 比赛信息
  areaMap = { 0: "地区赛", 1: "市赛", 2: "省赛" }, // 比赛阶段
  periodMap = { 1: "地区赛", 2: "市赛", 3: "省赛" }; // 过往成绩
</script>

<style lang="less" scoped>
.title {
  height: 36px;
  font-size: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  line-height: 36px;
  text-align: center;

  &:first-of-type {
    margin-top: 48px;
  }

  &:nth-of-type(2) {
    color: rgba(0, 0, 0, 0.6);
    margin-top: 24px;
  }
}

.sub-title {
  height: 44px;
  font-size: 36px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  line-height: 44px;
  margin-top: 24px;
  text-align: center;
}
</style>
