/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2024-04-19 17:16:46
 */
import { useUserStore, useCommonStore } from "@/store/index";
import { message } from "ant-design-vue";
// import { md5 } from 'md5js';
import dayjs from "dayjs";

export function getCopyRight() {
  const { hostname } = window.location,
    subdomain = hostname.match(/[^.]+\.[^.]+$/)?.[0];

  let obj = {};
  switch (subdomain) {
    case "scidsai.cn":
      obj = {
        copyRight: `Copyright ©2018-${new Date().getFullYear()} 广州华南数字科学与人工智能研究院`,
        icp: "粤ICP备2024195059号",
      };
      break;
    case "codedancing.cn":
      obj = {
        copyRight: `Copyright ©2018-${new Date().getFullYear()} 广东学思科技有限公司`,
        icp: "粤ICP备2023016999号",
      };
      break;
    default:
      obj = {
        copyRight: `Copyright ©2018-${new Date().getFullYear()} 测试版权信息`,
        icp: "粤ICP备1111111111号",
      };
      break;
  }
  return obj;
}

/**
 * @description: 内容拼接
 * @param {*} data
 * @return {*}
 */
export function parameterChange(data) {
  let res = ``;
  for (const key in data) {
    let dataKey = "";
    if (data[key] || data[key] === 0) dataKey = data[key];
    res = `${res}&${key}=${dataKey}`;
  }
  return res.substr(1);
}

/**
 * @description: 定时拍照
 * @param {*} executeImmediately 是否立刻执行
 * @return {*}
 */
export function screenshotRandomly(executeImmediately = false) {
  let counter = 1;
  const commonStore = useCommonStore();
  const { mediaRef, shotInterval } = commonStore;
  const randomTime = getRandomInterval();

  // 随机生成一个【1<random<4】或者【4<random<6】的时间
  function getRandomInterval() {
    const flag = Math.random() > 0.5,
      minMin = flag ? 4 * 60 * 1000 : 1 * 60 * 1000,
      intervalMin = 2 * 60 * 1000,
      random = Math.floor(Math.random() * intervalMin) + minMin; // 生成1到5分钟之间的随机数
    return random;
  }

  function do5() {
    const interval = setInterval(() => {
      if (counter > 5) {
        counter = 1;
        clearInterval(interval);
        return;
      }

      mediaRef.screenshot();
      counter++;
    }, randomTime);
  }

  executeImmediately && do5();

  const intervalId = setInterval(() => {
    do5();
  }, 30 * 60 * 1000);
  intervalId && commonStore.SET_SHOT_INTERVAL(intervalId);
}

/**
 * @description: 数字补0
 * @param {*} 数字
 * @return {*} 数字
 */
export function formatNumber(num) {
  num = num.toString();
  return num[1] ? num : `0${num}`;
}
