/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-03-01 10:01:22
 */
import { useUserStore } from '@/store/index';

const whiteList = ['login'];

export default function setupPermissionGuard (router) {
  router.beforeEach((to, from, next) => {
    const counter = useUserStore();

    if (whiteList.includes(to.name)) next();
    else counter.token ? next() : next({ path: '/user/login' });
  });
}
