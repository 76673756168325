<!--
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2023-07-21 09:29:40
-->
<template>
  <a-config-provider :locale="locale">
    <template v-if="isShowView">
      <router-view />
    </template>
    <errorTips v-else />

    <ContactBox v-if="isShowContact" />

    <div class="getMediaError" v-if="isShowMedia && !mediaOn"></div>
    <MediaDevices
      ref="mediaRef"
      v-if="isShowMedia"
      @cameraChange="(e) => onChangeCamera(e)"
    />
  </a-config-provider>
</template>

<script setup>
import { getCurrentInstance, ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useCommonStore, useUserStore } from "@/store";
import { ContactBox, MediaDevices } from "@/components";
import { browserRelease } from "@/utils";

import dayjs from "dayjs";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import "dayjs/locale/zh-cn";
import errorTips from "@/views/exception/errorTips.vue";

dayjs.locale("zh-cn");

let { proxy } = getCurrentInstance(),
  { VUE_APP_GAME } = process.env,
  locale = zhCN,
  commonStore = useCommonStore(),
  userStore = useUserStore();

const isShowView = computed(() => {
  const { isPc, isAllowedUse, isNeededUpdate, win } = browserRelease();
  return isPc && isAllowedUse && !isNeededUpdate && !win?.isXp && !win?.isWin7;
});

const isShowContact = computed(() => {
  return commonStore.isShowContact;
});

const mediaOn = ref(false),
  isShowMedia = computed(() => {
    return commonStore.isShowMedia && VUE_APP_GAME == "qck";
  }),
  onChangeCamera = (e) => {
    mediaOn.value = e;
    commonStore.SET_IS_CAMERA_ON(e);
  };
watch(
  () => userStore.state,
  (data) => {
    if ([0, 1].includes(data) && VUE_APP_GAME == "qck") {
      setTimeout(() => {
        commonStore.SET_MEDIAREF(proxy.$refs.mediaRef);
        proxy.$refs.mediaRef.openCamera();
      }, 0);
    }
  },
  { immediate: true }
);
</script>

<style lang="less" scoped>
.getMediaError {
  width: 100%;
  height: calc(100% - 96px);
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 96px;
  left: 0;
}
</style>
