<!--
 * @Author: gyh
 * @Date: 2024-04-10 15:14:59
 * @LastEditTime: 2024-04-12 18:20:50
-->
<template>
  <!-- 成绩公布 -->
  <div class="grades-box" v-if="state != 2">
    <template v-if="!loading">
      <template v-if="score?.showStatus">
        <!-- 获奖情况 -->
        <scoreType1 :score="score" v-if="VUE_APP_GAME == 'qck'" />
        <scoreType2 :score="score" v-else-if="VUE_APP_GAME == 'hdycp'" />
      </template>
      <div class="score-error-box" v-else>
        <img class="error-icon" src="@/assets/warning.png" alt="" />
        <span>查询失败，请联系相关工作人员...</span>
      </div>
    </template>
    <div class="spin-box" v-else>
      <a-spin tip="成绩查询中，请稍后..." />
    </div>
  </div>

  <!-- 成绩尚未公布 -->
  <div class="grades-box" v-else>
    <img src="@/assets/home/loading.png" alt class="loading-img" />
    <p class="loading-tips">比赛成绩尚未公布，请耐心等待</p>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, provide } from "vue";
import { useCommonStore } from "@/store";
import scoreType1 from "./score/scoreType1";
import scoreType2 from "./score/scoreType2";

const { proxy } = getCurrentInstance(),
  { VUE_APP_GAME } = process.env,
  commonStore = useCommonStore();

const props = defineProps({ state: Number });

/**
 * @description: 比赛成绩
 * @return {*}
 */
const loading = ref(true),
  score = ref(),
  getStudentScore = () => {
    proxy.$api.getStudentScore().then((res) => {
      if (res) {
        score.value = res.data;
        loading.value = false;
      }
    });
  },
  getPastStudentScore = () => {
    proxy.$api
      .getPastStudentScore()
      .then((res) => {
        if (res) {
          const { racePeriod, raceZone } = res.data;
          score.value = res.data;
          let comMess = commonStore.comMess;
          comMess.racePeriod = racePeriod;
          comMess.raceZonePast = raceZone;
        }
      })
      .finally(() => {
        loading.value = false;
      });
  },
  linkUrl = ref("https://mp.weixin.qq.com/s/a3rqwxQY_7W5vLsE01tppw");
defineExpose({ getStudentScore, getPastStudentScore });
</script>

<style lang="less" scoped>
.grades-box {
  width: 100%;
  height: fit-content;
  padding-bottom: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loading-img {
    width: 256px;
    height: 256px;
    margin-top: 24px;
  }

  .loading-tips {
    height: 44px;
    font-size: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    line-height: 44px;
    margin-top: 32px;
  }

  .score-error-box {
    min-height: 440px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;

    .error-icon {
      width: 64px;
      height: 64px;
      margin-right: 8px;
    }
  }

  .spin-box {
    min-height: 440px;
    display: flex;
    align-items: center;
    justify-content: center;

    :deep(.ant-spin-text) {
      font-size: 24px;
    }
  }
}
</style>
