/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-07-21 09:23:54
 */
import { defineStore } from 'pinia';

const useCommonStore = defineStore('common', {
  state: () => ({
    comMess: {},
    cameraInterval: '',
    isShowContact: false,
    isLoading: false,
    logId: null,
    isShowMedia: false,
    mediaRef: '',
    shotInterval: '',
    isShowScoreModal: false,
    isCameraOn: false,
  }),

  getters: {},
  actions: {
    SET_COMMESS(comMess) {
      this.comMess = comMess;
    },
    SET_CAMERA_INTERVAL(cameraInterval) {
      this.cameraInterval = cameraInterval;
    },
    CLEAR_CAMERA_INTERVAL() {
      clearInterval(this.cameraInterval);
      this.cameraInterval = '';
    },
    SET_IS_SHOW_CONTACT(isShowContact) {
      this.isShowContact = isShowContact;
    },
    SET_IS_LOADING(isLoading) {
      this.isLoading = isLoading;
    },
    SET_LOGID(logId) {
      this.logId = logId;
    },
    SET_IS_SHOW_MEDIA(isShowMedia) {
      this.isShowMedia = isShowMedia;
    },
    SET_MEDIAREF(mediaRef) {
      this.mediaRef = mediaRef;
    },
    SET_SHOT_INTERVAL(shotInterval) {
      this.shotInterval = shotInterval;
    },
    CLEAR_SHOT_INTERVAL() {
      clearInterval(this.shotInterval);
      this.shotInterval = '';
    },
    SET_IS_SHOW_SCORE_MODAL(isShowScoreModal) {
      this.isShowScoreModal = isShowScoreModal;
    },
    SET_IS_CAMERA_ON(isCameraOn) {
      this.isCameraOn = isCameraOn;
    },
  },
});

export default useCommonStore;
