/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2023-07-11 15:53:56
 */
import { request } from '@/request/request';

export default {
  // 上传图片
  uploadImage(data) {
    return request({
      url: '/file/uploadImage',
      method: 'post',
      data,
    });
  },
};
