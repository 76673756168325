/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-07-20 10:08:28
 */
import setupPermissionGuard from "./permission";
import * as api from "@/api/index";
import { useUserStore, useCommonStore } from "@/store/index";
import { message } from "ant-design-vue";
import { browserRelease } from "@/utils/browserUtils";

let timer = null;

function setupPageGuard(router) {
  router.beforeEach((to, from, next) => {
    if (
      process.env.NODE_ENV !== "development" &&
      window.location.protocol !== "https:"
    ) {
      const { hostname, host, port } = window.location;
      window.location.href = "https://" + port ? host : hostname;
    }

    clearInterval(timer);

    // 隐藏右侧联系框显示
    useCommonStore().SET_IS_SHOW_CONTACT(
      !["home", "enrollForm", "examination"].includes(to.name) &&
        browserRelease().isPc
    );
    useCommonStore().SET_IS_SHOW_MEDIA(!["login"].includes(to.name));

    if (to.name != "login" && sessionStorage.getItem("token")) {
      api.default
        .getStuState()
        .then((res) => {
          if (res) {
            const { stuState, raceState } = res.data;

            const isExamState = [0, 1].includes(stuState);

            // 展示联系框
            useCommonStore().SET_IS_SHOW_CONTACT(
              to.name == "home" && isExamState
            );
            useCommonStore().SET_IS_SHOW_MEDIA(isExamState);
            useUserStore().SET_STATE(stuState);
            if (to.name === "waiting") {
              if (from.name === "examination") {
                next("/index");
                return;
              }
              if (raceState) next("/examination");
              else next();
              useCommonStore().SET_IS_LOADING(false);
            }
            // 等待页有时间判断，避免触发
            else if (to.name === "examination") {
              console.log("stuState:", stuState, "raceState:", raceState);
              if (stuState == 0) next();
              else if (stuState == 1 && raceState) next();
              else {
                next("/index");
                message.error("非规定时间进入考试页!");
                useCommonStore().SET_IS_LOADING(false);
              }
            } else next();
          }
        })
        .catch(() => {
          useCommonStore().SET_IS_LOADING(false);
        });
    } else next();
  });
  router.afterEach((to, from) => {
    if (to.name == "home") {
      timer = setInterval(() => {
        api.default.getStuState().then((res) => {
          const { stuState, raceState } = res.data;

          if (stuState == undefined) clearInterval(timer);
          useUserStore().SET_STATE(stuState);
        });
      }, 10000);
    } else if (to.name == "examination") useCommonStore().SET_IS_LOADING(false);
  });
}

export default function createRouteGuard(router) {
  setupPageGuard(router);
  setupPermissionGuard(router);
}
