/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-03-14 19:20:16
 */
import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/index',
    redirect: '/home',
    name: 'index',
    component: BaseLayout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: '/enrollForm',
        name: 'enrollForm',
        component: () => import('@/views/home/enrollForm.vue'),
      },
      {
        path: '/waiting',
        name: 'waiting',
        component: () => import('@/views/home/waiting.vue'),
      },
    ],
  },
  {
    path: '/examination',
    name: 'examination',
    component: () => import('@/views/examination/examination.vue'),
  },
];
