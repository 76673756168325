<template>
  <div class="error_tips" :style="{ background: url(bgMap['VUE_APP_GAME']) }">
    <template v-if="browserRelease().isPc">
      <!-- 公告 -->
      <notice />
      <!-- 错误提示 -->
      <pcTips />
    </template>
    <!-- 错误提示 -->
    <mobileTips v-else />
  </div>
</template>

<script setup>
import { browserRelease } from "@/utils/browserUtils";

import pcTips from "./pcTips.vue";
import mobileTips from "./mobileTips.vue";
import notice from "@/components/notice.vue";

const { VUE_APP_GAME } = process.env;

// 背景图
const bgMap = {
  qck: require("@/assets/login/bg__qck.jpg"),
  hdycp: require("@/assets/login/bg__hdycp.jpg"),
  sdszsy: require("@/assets/login/sdszsyLoginBg.png"),
};
</script>

<style lang="less" scoped>
.error_tips {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
