<!--
 * @Date: 2023-07-14 10:22:20
 * @LastEditTime: 2023-07-14 11:35:21
-->
<template>
  <div class="score_modal" ref="scoreModalRef">
    <a-modal
      :getContainer="() => $refs.scoreModalRef"
      :maskClosable="false"
      :closable="false"
      :footer="null"
      v-model:visible="visible"
    >
      <div class="modal_top">
        <div class="modal_title">成绩查询</div>
        <div class="close" @click="commonStore.SET_IS_SHOW_SCORE_MODAL(false)">
          x
        </div>
      </div>

      <div class="content_box" ref="contentRef">
        <CompetitionMess :comMess="competitionMess" />

        <Score :state="3" ref="scoreRef" />
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance, watch } from 'vue';
import CompetitionMess from './competitionMess.vue';
import Score from '@/views/home/score.vue';
import { useCommonStore } from '@/store';

const { proxy } = getCurrentInstance(),
  commonStore = useCommonStore();

const visible = computed(() => {
    return commonStore.isShowScoreModal;
  }),
  competitionMess = computed(() => {
    return commonStore.comMess;
  });
watch(visible, (visible) => {
  if (visible)
    setTimeout(() => {
      proxy.$refs.scoreRef.getPastStudentScore();
    }, 0);
  else {
    proxy.$refs.contentRef.scrollTo({ top: 0 });
    let comMess = commonStore.comMess;
    delete comMess.racePeriod;
    delete comMess.raceZonePast;
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal) {
  width: fit-content !important;
  min-width: 1004px !important;
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  outline: 5px solid #00a870;
  padding: 0;
  overflow: hidden;

  .ant-modal-content {
    width: 100%;
    height: 100%;

    .ant-modal-body {
      width: 100%;
      height: 100%;
      padding: 0;

      .modal_top {
        width: 100%;
        height: 80px;
        background: #00a870;
        border-radius: 24px 24px 24px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .modal_title {
          height: 36px;
          font-size: 28px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.9);
          line-height: 36px;
        }

        .close {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          font-size: 20px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .content_box {
        max-height: 700px;
        padding: 36px 40px 0 40px;
        box-sizing: border-box;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      .title:first-of-type {
        margin-top: unset;
      }
    }
  }
}
</style>
