<!--
 * @Date: 2023-05-18 17:31:12
 * @LastEditTime: 2024-04-26 11:02:27
-->
<template>
  <div class="contact_box">
    <div
      v-for="contact in contacts"
      class="contact"
      :key="contact.id"
      @click="go2page"
      @mouseenter="contact.isActive = true"
      @mouseleave="mouseleave"
    >
      <img :src="contact.icon" class="icon" />
      <p class="name">{{ contact.name }}</p>

      <template v-if="VUE_APP_GAME != 'sdszsy'">
        <div class="show_box" v-show="contact.isActive">
          <img class="code" :src="contact.code" v-if="contact.code" />
          <p
            :class="['text', { special_text: contact.isSpecial }]"
            v-html="contact.text"
          ></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watchEffect } from "vue";

let { proxy } = getCurrentInstance(),
  { VUE_APP_GAME } = process.env;

const contacts = reactive([]),
  mouseleave = () => {
    const cache = [...contacts];
    contacts.length = 0;
    cache.forEach((contact) => {
      contact.isActive = false;
      contacts.push(contact);
    });
  },
  go2page = () => {
    if (VUE_APP_GAME != "sdszsy") return;

    const link = document.createElement("a");
    link.href =
      "https://doc.weixin.qq.com/doc/w3_AIQAJAaZAFoQbxofJhCSQaqA7UFdU?scode=APYANQezABAlwr0y6xAIQAJAaZAFo";
    link.target = "blank";
    document.body.appendChild(link);
    link.click();
  };

watchEffect(() => {
  let arr = [];
  if (VUE_APP_GAME == "sdszsy")
    arr = [
      {
        id: 0,
        name: "平台使用说明",
        text: "平台使用说明",
        icon: require("@/assets/contact/eventRules.png"),
        code: require("@/assets/contact/instructions.png"),
        isActive: false,
      },
    ];
  else if (VUE_APP_GAME == "hdycp")
    arr = [
      {
        id: 1,
        name: "赛事规则",
        text: "扫码获取参赛报名表和承诺书",
        icon: require("@/assets/contact/eventRules.png"),
        code: require("@/assets/contact/hdycp/rule.png"),
        isActive: false,
      },
    ];
  else
    arr = [
      {
        id: 0,
        name: "平台使用说明",
        text: "平台使用说明",
        icon: require("@/assets/contact/eventRules.png"),
        code: require("@/assets/contact/instructions.png"),
        isActive: false,
      },
      {
        id: 1,
        name: "赛事规则",
        text: "赛事规则",
        icon: require("@/assets/contact/eventRules.png"),
        code: require("@/assets/contact/qck/rule.png"),
        isActive: false,
      },
      {
        id: 2,
        name: "赛事咨询",
        text: "赛事咨询",
        icon: require("@/assets/contact/consultation.png"),
        code: require("@/assets/contact/qck/consulting.png"),
        isActive: false,
      },
      {
        id: 3,
        name: "赛事通知群 1",
        text: "华工软件编程挑战赛通知群1<br/>QQ群号: 463737040",
        icon: require("@/assets/contact/notificationGroup.png"),
        code: require("@/assets/contact/qck/1.png"),
        isActive: false,
        isSpecial: true,
      },
      {
        id: 4,
        name: "赛事通知群 2",
        text: "华工软件编程挑战赛通知群2<br/>QQ群号: 615294745",
        icon: require("@/assets/contact/notificationGroup.png"),
        code: require("@/assets/contact/qck/2.png"),
        isActive: false,
        isSpecial: true,
      },
      {
        id: 5,
        name: "赛事通知群 3",
        text: "华工软件编程挑战赛通知群3<br/>QQ群号: 592900132",
        icon: require("@/assets/contact/notificationGroup.png"),
        code: require("@/assets/contact/qck/3.png"),
        isActive: false,
        isSpecial: true,
      },
      {
        id: 6,
        name: "技术咨询",
        text: `
        <ul style="list-style-type: none">
          <li style="line-height: 0.125rem;">技术咨询QQ：529750028</li>
        </ul>`,
        // <li style="line-height: 0.125rem;">技术咨询电话：18666717012</li>
        // <li style="line-height: 0.125rem;">技术咨询电话：13535088091</li>
        icon: require("@/assets/contact/consultation.png"),
        isActive: false,
        isSpecial: true,
      },
    ];

  arr.forEach((item) => {
    contacts.push(item);
  });
});
</script>

<style lang="less" scoped>
.contact_box {
  height: fit-content;
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // bottom: 392px;
  right: 0;

  .contact {
    min-width: 164px;
    height: 40px;
    background: #0052d9;
    border-radius: 4px;
    padding: 0 16px;
    // padding-left: 16px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:first-of-type) {
      margin-top: 8px;
    }

    .icon {
      width: 28px;
      height: 28px;
    }

    .name {
      height: 28px;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 28px;
      margin-left: 8px;
    }

    .show_box {
      width: fit-content;
      min-width: 248px;
      height: fit-content;
      padding: 8px 4px 10px 4px;
      box-sizing: border-box;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: -256px;

      .code {
        width: 240px;
        height: 240px;
      }

      .text {
        text-align: center;
        margin-top: 8px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;

        &.special_text {
          margin-top: 2px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.9);
          line-height: 20px;
        }
      }
    }
  }
}
</style>
