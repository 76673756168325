/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-07-17 13:32:22
 */
import router from "@/router";
import api from "@/api";
import { defineStore } from "pinia";
import { message, Modal } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    name: "",
    phone: "",
    state: -1,
    raceId: 0,
    stuId: 0,
  }),

  getters: {
    userInfo(state) {
      return { ...state };
    },
  },
  actions: {
    SET_TOKEN(token) {
      this.token = token;
    },
    SET_NAME(name) {
      this.name = name;
    },
    SET_PHONE(phone) {
      this.phone = phone;
    },
    SET_STATE(state) {
      this.state = state;
    },
    SET_RACEID(raceId) {
      this.raceId = raceId;
    },
    SET_STUID(stuId) {
      this.stuId = stuId;
    },
    login(params) {
      return new Promise((resolve, reject) => {
        api.login(params).then((res) => {
          if (res.resultCode === 200) {
            const { token, name, phone, raceId, stuId } = res.data;
            this.token = token;
            this.name = name;
            this.phone = phone;
            this.raceId = raceId;
            this.stuId = stuId;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("name", name);
            sessionStorage.setItem("phone", phone);
            sessionStorage.setItem("raceId", raceId);
            sessionStorage.setItem("stuId", stuId);
          }
          resolve(res);
        });
      });
    },
    logout() {
      this.token = "";
      this.name = "";
      this.phone = "";
      this.state = -1;

      sessionStorage.removeItem("token");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("comMess");
      sessionStorage.removeItem("raceId");
      sessionStorage.removeItem("stuId");
    },
  },
});

export default useUserStore;
