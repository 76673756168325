/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2023-07-12 15:19:38
 */
import { request } from '@/request/request';

export default {
  // 公告
  getNoticeInfo(data) {
    return request({
      url: '/tbl-config-info/getNoticeInfo',
      method: 'get',
    });
  },
};
