<template>
  <div class="pc_error_tips">
    <div class="modal_box">
      <img src="@/assets/warning.png" alt="warning" class="svg" />

      <!-- 非谷歌内核访问-->
      <div class="tips" v-if="!browserInfo.isAllowedUse">
        <!-- windows系统 -->
        <template v-if="Object.keys(browserInfo.win).length > 0">
          <!-- XP系统 -->
          <template class="tips" v-if="browserInfo?.win?.isXp">
            您当前电脑配置不符合比赛要求，请更换电脑后再登录进入！<br />要求操作系统：Windows
            7、Windows 10或更高版本！
          </template>

          <!-- win7+ -->
          <template v-else>
            请使用最新版本的Chrome（谷歌）浏览器进行访问<br />
            下载地址：<a
              :href="
                browserInfo.win.isX64
                  ? 'https://cdn.codedancing.cn/contest/useBrowser/chrome/ChromeStandaloneSetup64.exe'
                  : 'https://cdn.codedancing.cn/contest/useBrowser/chrome/Google_Chrome_%2832bit%29_v109.0.5414.120.exe'
              "
              target="_blank"
              >Chrome</a
            >
          </template>
        </template>
        <!-- 其他系统 -->
        <template v-else>
          请使用最新版本的Chrome（谷歌）浏览器进行访问<br />
          下载地址：<a
            href="https://cdn.codedancing.cn/contest/useBrowser/chrome/googlechrome.dmg"
            target="_blank"
            >Chrome</a
          >
        </template>
      </div>

      <!-- 浏览器版本过低 -->
      <div class="tips" v-else-if="browserInfo.isNeededUpdate">
        <!-- TODO 更换谷歌浏览器下载地址 -->
        您当前浏览器的版本过低（版本：{{
          browserInfo.version
        }}），建议使用<br />不低于{{
          browserInfo.lowestVersions
        }}版本的Chrome浏览器访问。如果出现不能访问页面情况，<br />请您升级浏览器版本再尝试！
        下载链接：<a
          href="https://www.google.com/intl/zh-CN/chrome/"
          target="_blank"
          >Chrome</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { browserRelease } from '@/utils/browserUtils';

import browserList from '@/config/browserList';

import pcTips from './pcTips.vue';
import mobileTips from './mobileTips.vue';

const browserInfo = computed(() => {
  return { ...browserRelease() };
});
</script>

<style lang="less" scoped>
.pc_error_tips {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  .modal_box {
    width: 480px;
    height: fit-content;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .svg {
      width: 80px;
      height: 80px;
    }

    .tips {
      font-size: 14px;
      font-weight: 600;
      color: rgba(61, 61, 61, 0.898);
      text-align: center;
      margin-top: 8px;
    }
  }
}
</style>
