/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2023-03-14 18:13:14
 */
import { request } from '@/request/request';
import { parameterChange } from '@/utils/utils';

export default {
  // 学生端登录
  login(data) {
    return request({
      url: '/auth/login',
      method: 'post',
      data,
    });
  },

  // 获取登录验证码
  uuid(data) {
    return request({
      url: '/auth/uuid',
      method: 'get',
    });
  },

  // 发送手机验证码
  sendSms(data) {
    return request({
      url: '/auth/sendSms',
      method: 'post',
      data,
    });
  },

  // 修改密码
  updatePassWord(data) {
    return request({
      url: '/auth/updatePassWord',
      method: 'post',
      data,
    });
  },
};
